import React from 'react'
import SEO from '../components/seo/seo'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import faq from '../docs/title-insurance-faq.pdf'



const FAQPage = () => (
  <>
    <SEO
      title="Title Insurance"
      description="Pleasant Street Title & Closing is a full service title company offering services for brokered and non brokered real estate transactions. Helping buyers, sellers and lenders including closings, title searches, title insurance, mortgage discharges and deeds by experienced real estate professionals overseen by Attorney James G. Feleen, Esq."
      keywords={['pleasant street', 'pleasant street title & closing', 'title', 'title insurance', 'title search', 'closing', 'closings', 'buyer', 'buyers', 'seller', 'sellers', 'lender', 'lenders', 'real estate', 'realtor', 'broker', 'mortgage', 'real estate lawyer', 'real estate attorney', 'law', 'lawyer', 'law office', 'attorney', 'James Feleen', 'Jim Feleen', 'Claremont', 'NH', 'New Hampshire', 'Claremont NH', 'sullivan county', 'grafton county', 'cheshire county', 'merrimack county', 'Acworth', 'Charlestown', 'Cornish', 'Croydon', 'Goshen', 'Grantham', 'Langdon', 'Lempster', 'Newport', 'Plainfield', 'Springfield', 'Sunapee', 'Unity', 'Washington']} />

    <div className="body-wrapper">
      <Banner />
      <h2>Title Insurance</h2>
      <h3>Lenders Title Insurance</h3>
      <p>When you purchase a home, you're buying more than the house and the land it sits on. You are also buying the property's ownership history, called the "chain of title". If there is a problem with the chain of title, you own the problem in addition to your home. "Defect" or "cloud on" are terms used to describe a problem with the chain of title. Although the sellers may be obligated to help you cure the title, they may not be available or solvent.</p>

      <p>Title insurance protects from losses resulting from defects in the title of real property. Title insurance companies research and evaluate the history of a property to make certain that there is nothing in the property's history that could result in a loss to the insured.</p>

      <p>There are two types of title insurance, lenders title insurance and owners title insurance. Most lenders require the purchase of lenders title insurance when they issue a loan. It is based on the dollar amount of the loan and protects the lender's interest in the property against title problems.</p>

      <p>Lenders title insurance protects only the lender's interest in the property, not the owner's. To have title protection, owners must purchase a separate policy, called an Owners Policy, that insures their interests.</p>

      <h3>Owners Title Insurance</h3>
      <p>Purchasing an owners title insurance policy protects your investment against possible title defects and claims against your property, providing you with the protection that a lenders policy provides only to your lender. </p>

      <ul>
        <li>Insures against title defects not covered by an attorney's certification of title</li>
        <li>Helps when you want to sell your property</li>
        <li>Provides you with immediate and expert legal support in the event of a title challenge</li>
        <li>Protects your most important investment, your home!</li>
      </ul>

      <p>Pleasant Street Title & Closing, LLC offers both lenders and owners title insurance through the Chicago Title Insurance Co. (CTIC). Please visit the consumer info page at CTIC <a href="https://www.ctic.com/Consumers.aspx" target="_blank" rel="noopener noreferrer">here</a>.</p>

      <h3>Frequently Asked Questions</h3>
      <p><a href={faq} target="_blank" rel="noopener noreferrer">Click here</a> to view a PDF with some frequently asked questions and other useful information related to Title Insurance.</p>
    </div>
  </>
)

const Banner = props => (
  <StaticQuery
    query={query}
    render={data => (
      <>
        <Img alt="House with lights on" className="hero-image sub-page rounded" fluid={data.file.childImageSharp.fluid}/>
      </>
    )}
  />
);

const query = graphql`
  query {
    file(relativePath: { eq: "house.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 90) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
            ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default FAQPage
